* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}

.Row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Row-letter {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Row-locked-in {
  cursor: pointer;
}

.Row-letter-color {
  margin: 2px;
  border: 2px solid rgba(128, 128, 128, 0.8);
  flex: 1;
  max-width: 40px;
  height: 40px;
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.Fib-flag-container
{ 
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
  position: absolute;
}

.Fib-flag
{
  align-self: flex-start;
  font-size: 14px;
}

td.letter-locked.letter-flagged {
  border: 2px dotted black;
}

body.dark td.letter-locked.letter-flagged {
  border: 2px dotted black;
}

td.letter-locked
{
  border: 4px solid rgb(44, 120, 122);
  border-radius: 10px;
}

.Game-keyboard-button.letter-locked
{
  border: 4px solid rgb(44, 120, 122);
  border-radius: 10px;
}

body.dark td.letter-locked
{
  border: 4px solid rgb(122, 251, 255);
  border-radius: 10px;
}

body.dark .Game-keyboard-button.letter-locked
{
  border: 4px solid rgb(122, 251, 255);
  border-radius: 10px;
}

.Row-annotation {
  margin-inline-start: 16px;
  width: 5em;
  text-align: end;
  margin-left: -80px;
  padding-left: 80px;
  pointer-events:none;
}

.App-container {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.App-container h1 {
  margin-top: 0;
  margin-bottom: 5px;
}

.Game,
h1 {
  user-select: none;
}

.Game {
  display: flex;
  flex-direction: column;
}

table.Game-rows {
  margin: auto;
  display: flex;
  flex-direction: column;
}

table.Game-rows:focus {
  outline: none;
}

table.Game-rows > tbody {
  display: flex;
  flex-direction: column;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background-color: #cdcdcd;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

.debug {
  visibility: collapse;
}

:root {
  --correct-color: rgb(87, 172, 120);
  --elsewhere-color: rgb(233, 198, 1);
  --absent-color: rgb(162, 162, 162);

  --correct-color-colorblind: rgb(87, 172, 120);
  --elsewhere-color-colorblind: rgb(233, 198, 1);
  --absent-color-colorblind: rgb(162, 162, 162);
}

.shake-long
{
  /*animation-name: shake-long;
  animation-duration: .7s;*/
}

.shake-short
{
  /*animation-name: shake;
  animation-duration: .5s;*/
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


@keyframes shake-long {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

/*
.Row-num-0.letter-correct, 
.Row-num-0.letter-correct-fin,
.Row-num-1.letter-correct, 
.Row-num-1.letter-correct-fin   {
  animation-name: shake;
  animation-duration: .2s;
}

.Row-num-0.letter-elsewhere, 
.Row-num-0.letter-elsewhere-fin,
.Row-num-1.letter-elsewhere, 
.Row-num-1.letter-elsewhere-fin {
  animation-name: shake;
  animation-duration: .15s;
}

.Row-num-0.letter-absent,
.Row-num-1.letter-absent {
  animation-name: shake;
  animation-duration: .1s;
}
*/

.letter-correct, .letter-correct-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(87, 172, 120);
  color: white !important;

}

.letter-elsewhere, .letter-elsewhere-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgb(233, 198, 1);
  color: white !important;
}

.letter-correct-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(87, 172, 120);
  color: white !important;
}

.letter-elsewhere-fin {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(233, 198, 1);
  color: white !important;
}

.letter-absent, 
.Game-keyboard-button.letter-correct-fin,
.Game-keyboard-button.letter-elsewhere-fin,
.App-container.color-blind .Game-keyboard-button.letter-correct-fin, 
.App-container.color-blind .Game-keyboard-button.letter-elsewhere-fin {
  border: 2px solid transparent;
  background-color: rgb(162, 162, 162);
  color: white !important;
}

.letter-known-fib {
  border: 2px dotted black;
}

body.dark {
  background-color: #404040;
  color: #e0e0e0;
  color-scheme: dark;
}

body.dark .Game-keyboard-button {
  color: #404040;
}

a,
a:visited {
  color: #8080ff;
}

a:active {
  color: #cc77ff;
}

.Game-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.Game-options > * + * {
  margin-inline-start: 0.5rem;
}

.Game-options button {
  min-width: 4rem;
}

.Game-name-mode-container {
  
}

.Game-name {
  color: inherit;
  font-style: inherit;
  margin-right: 5px;
}

.Game-modes {
  box-sizing:unset;
}

.Game-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  width: 100%;
}

.Game-stats h1 {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
}

.Game-stats-notice {
  font-size: 12px;
  text-align: center;
  max-width: 300px;
  margin-top: 10px;
  text-align: left;
}

.Game-stats-games {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.stat {
  flex: 1;
  margin: 0 10px;
}

.stat-num {
  font-size: 36px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  font-variant-numeric: proportional-nums;
}

.stat-label {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Game-stats-guesses {
  padding-bottom: 10px;
  width: 80%;
}

.guess-stat {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  margin: 3px;
}

.guess-count {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  padding-top: 3px;
}

.guess-graph {
  width: 100%;
  height: 100%;
  padding-left: 4px;
    border-radius: 5px;
}

.guess-bar {
  height: 20px;
  line-height: 20px;
  width: 0%;
  position: relative;
  background-color: black;
  display: flex;
  justify-content: flex-end;
  padding-right: 7px;
  border-radius: 5px;
}

.guess-games {
  font-weight: bold;
  color: white;
  height: 20px;
  line-height: 20px;
}


.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
  text-align: left;
}

.App-about b {
  background-color: #888;
  color: white;
  padding: 1px 3px;
  border-radius: 2px;
}

.App-about b.green-bg {
  background-color: rgb(87, 172, 120);
}

.App-about b.yellow-bg {
  background-color: #e9c601;
}

.Game-seed-info {
  opacity: 0.7;
  margin-top: 1em;
  font-variant-numeric: tabular-nums;
}

.Game-sr-feedback,
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Settings {
  text-align: left;
  font-size: 18px;
}

.Settings-setting {
  margin: 8px;
  display: flex;
  align-items: top;
}

.Settings-setting input {
  width: 18px;
  height: 18px;
}

.Settings-setting input[type="range"] {
  width: 50px;
  height: 18px;
}

.Settings-setting label {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.emoji-link {
  font-size: 24px;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.top-right > * + * {
  margin-inline-start: 8px;
}

.App-container.color-blind .letter-correct,
.App-container.color-blind .letter-correct-fin,
.App-container.color-blind .App-about b.green-bg {
  background-color: #f5793a;
}

.App-container.color-blind .letter-elsewhere,
.App-container.color-blind .letter-elsewhere-fin,
.App-container.color-blind .App-about b.yellow-bg {
  background-color: #85c0f9;
}

.react-calendar__tile pre {
  height: 100%;
  font-size: 6px;
}

a { 
  text-decoration: inherit;
}

.ModeEnabled
{
  border-radius: .375rem;
  padding: .3rem .45rem;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-decoration: inherit;
  transition-property: color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.ModeEnabled, .ModeDisabled:hover {
  background-color: rgb(72, 77, 87) !important;
  color: rgb(209 213 219);
}

.ModeEnabled.dark, .ModeDisabled.dark:hover {
  background-color: rgb(65, 72, 82) !important;
  color: rgb(209 213 219);
}

.ModeDisabled
{
  border-radius: .375rem;
  padding: .3rem .45rem;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-decoration: inherit;
  color: rgb(209 213 219);
  transition-property: color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.link-Image {
  background-repeat: no-repeat;
  background-size: cover;

  font-size: 24px;
  text-decoration: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 1px;

  width: 24px;
  height: 24px;
}

.link-About {
  background-image: url("info.png");  
}

.link-Stats {
  background-image: url("stats.png");  
}

.link-Settings {
  background-image: url("settings.png");  
}

.link-Calendar {
  background-image: url("calendar.png");  
}


.popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: visible;
  opacity: 1;
  z-index: 1000;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  color: black;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .popup-close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .popup-close:hover {
  color: #06D85F;
}

.popup .popup-content {
  max-height: 30%;
  overflow: auto;
  text-align: left;
}

.limerick {
  display: flex;
  justify-content: center;
  width: 100%;
}